
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$amazonia-primary: mat.define-palette(mat.$indigo-palette);
$amazonia-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$amazonia-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$amazonia-theme: mat.define-light-theme((
  color: (
    primary: $amazonia-primary,
    accent: $amazonia-accent,
    warn: $amazonia-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($amazonia-theme);

/* You can add global styles to this file, and also import other style files */
@import './assets/styles/variables.scss';
@import './assets/styles/typography.scss';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

html, body {
    font-family: $font-open-sans;
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

button {
    background: none;
    border: none;
    cursor: pointer;
}

* {
    box-sizing: border-box;
}
