@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-VariableFont.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Italic';
    src: url('/assets/fonts/OpenSans-Italic-VariableFont.ttf') format('truetype');
    font-weight: 100 900;
    font-style: italic;
}

$font-open-sans: 'Open Sans', sans-serif;
