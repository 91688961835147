@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin section-center {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
}

@mixin text-style($size, $font-style, $weight, $lineHeight) {
    font-size: $size;
    font-style: $font-style;
    font-weight: $weight;
    line-height: $lineHeight;
}

@mixin div-size($height, $width) {
    height: $height;
    width: $width;
}

$box-shadow: 0px 1px 3px 0px rgba(18, 18, 23, 0.10), 0px 1px 2px 0px rgba(18, 18, 23, 0.06);

/* Primary Colors */
$primary-100: #E8F5E8;
$primary-200: #C9F0C9;
$primary-300: #A2E0A2;
$primary-400: #88D488;
$primary-500: #69C669;
$primary-600: #44A344;
$primary-650: #4E8A6E;
$primary-700: #2E8D2E;
$primary-800: #1A5C2A;
$primary-900: #0D3316;
$primary-950: #0C3701;
$primary-green-light: #008647;

/* Secondary Colors */
$secondary-100: #E4F3FF;
$secondary-200: #BAD5FF;
$secondary-300: #96B9FF;
$secondary-400: #5C90F7;
$secondary-500: #2F6FED;
$secondary-600: #1D5BD6;
$secondary-700: #1E4EAE;
$secondary-800: #093080;
$secondary-900: #00194A;

/* Neutral Colors */
$neutral-100: #FFF;
$neutral-200: #F8F9FC;
$neutral-300: #F1F3F9;
$neutral-400: #E1E6EF;
$neutral-500: #D1D9E9;
$neutral-550: #D9D9D9;
$neutral-600: #B4C0D6;
$neutral-650: #6C6C89;
$neutral-700: #445A73;
$neutral-800: #35485D;
$neutral-900: #233345;
$neutral-950: #EDFDEF;
$neutral-light: #FAFBFD;
$neutral-extra-light: #EBEBEA;

/* Grey Colors */
$grey-50: #D8D8D8;
$grey-100: #D1D1DB;
$grey-150: #DBDBDB;
$grey-200: #F7F7F8;
$grey-250: #808087;
$grey-275: #79747E;
$grey-300: #3F3F50;
$grey-400: #2D2D35;
$grey-500: #313131;
$grey-550: #3E3E3E;
$grey-600: #464B52;
$grey-650: #3A3A3A;
$grey-700: #545F71;

/* Black Colors */
$black-100: #000;
$black-200: #121217;
$black-300: #1D1D1D;

/* Red Colors */
$red-100: #E44337;
$red-200: #D50B3E;

/* Green Colors */
$green-100: #4BB665;
